import React from 'react'
import Helmet from 'react-helmet'
import Layout from '../components/layout'
import { StaticImage } from "gatsby-plugin-image"

// import pic11 from '../assets/images/pic11.jpg'

const Generic = (props) => (
    <Layout>
        <Helmet>
            <title>可視化研修</title>
            <meta name="description" content="都市構造可視化の活用のための研修の実施および支援の事業についての概要" />
        </Helmet>

        <div id="main" className="alt">
            <section id="one">
                <div className="inner">
                    <header className="major">
                        <h1>可視化研修のご案内</h1>
                    </header>
                    <span className="image main"><img src="/kensyu.jpg" alt="" style={{height:"300px",objectFit: "cover"}} /></span>
                    <h3>研修委員会主査よりご挨拶
                        </h3>
                        <div>
                            <div>
                                <StaticImage src="../images/tomo.png" alt="imgae" width={100}/>
                            </div>
                            <div>
                                <p>　都市構造可視化計画は誰でも使えるウェブサイトとして公開されており、全国の可視化が可能です。最初は難しく感じるかもしれませんが、この研修では、実際に使い方を体験していただきながら、誰でも使いこなせるようになっていただけます。<br/>当初は時間をかけて研修を行っていましたが、現場での活用を念頭に研修方法を見直し、<br/>
                                （１）密度の濃い１日の技術習得<br/>（２）業務での実際の活用（そのための随時サポート）<br/>（３）その成果報告会<br/>という三段階の構成として現在のプログラムを完成させました。特に研修中に体得していただく、３Dマウスとストリートビューを組み合わせた技を使えるようになると、プレゼンの見栄えがグンと良くなります。
                                </p><p>
                                　この研修の特徴は、みなさんに現場で実際に使っていただくためのサポートを期間中に行うことです。お渡しする機材を使ったリモート会議で可視化活用のご相談をお受けしながら、独自データの可視化などをご提案し皆様の活用をお手伝いします。こうしたサポートを通じて、皆さんの活用事例が充実することで、毎回の事例発表会は大変な盛り上がりとなります。皆さん一人ひとりのアイデアで可視化とまちづくりの新たな世界が広がる、そんな可能性を感じることができるのがプロボノ講師の喜びです。<br/>皆様からのお申し込みを楽しみにお待ちしております。<span style={{color:"red"}}></span></p>
                            </div>
                            <div>
                                <p>※ 研修の内容は、ご要望に応じて作成いたします。<br/>
                                    ※ 4-6人程度を標準にしていますが、最大で300人規模の開催実績があります。<br/>
                                ※ 下の研修イメージ動画もご覧ください。<br/>
                                </p>
                            </div>
                            <div style={{display:"flex", width:"100vw"}}>
                                <div style={{margin:"0.5%", width:"30%"}}>
                                    <iframe width="100%" src="https://www.youtube.com/embed/G9GpggxoT60" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen=""></iframe>
                                </div>
                                <div style={{margin:"0.5%", width:"30%"}}>
                                    <iframe width="100%" src="https://www.youtube.com/embed/ShsUwdBRwdA" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen=""></iframe>
                                </div>
                                <div style={{margin:"0.5%", width:"30%"}}>
                                    <iframe width="100%" src="https://www.youtube.com/embed/n0ucqbIjlX8" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen=""></iframe>
                                </div>
                            </div>
                        </div>
<hr/>
                        <h3>
                            １．研修支援業務の目的
                        </h3>
                        <p>
                        　本業務は、公益社団法人日本都市計画学会等が運営している、都市構造の理解がより空間的・数値的・直感的に得られる情報基盤である「都市構造可視化ウェブサイト」の使い方を習得するために、研修プログラムを活用し、地方公共団体等行政機関、教育機関、民間事業者等におけるコミュニケーションツール等として活用することを目的としています。
                        </p>
                        <h3>
                            ２．研修支援業務の内容
                        </h3>
                        <h4>
                            １）開催準備
                        </h4><p>
                        　研修会の開催にあたって、プログラム及び配布資料、機器等の準備を行います。</p>
                        <h4>
                            ２）研修会運営
                        </h4>
                        <p>
                        　都市構造可視化に関する研修会の、講義、進行ほか運営支援を行います。<br/>
                        　開催の流れは以下の通りです。</p>
                        <h5>１回目：使い方研修【１日程度】</h5>
                        <p>　都市構造可視化に関する知識習得のための講演・実技</p>
                        <h5>１回目から２回目までの間：現場での活用【2〜10ヶ月程度】</h5>
                        <p>　現場での可視化活用を実践していただけます。
                        </p>
                        <p>　独自データの可視化など、可視化についての相談およびサポート（データ変換含む）をリモート会議で随時行います。</p>
                        <h5>２回目：活用事例報告会【半日程度】</h5>
                        <p>　研修参加者の活用事例の報告会<br/>　参加者全員にパワーポイント８枚、１０分程度の発表をしていただきます。</p>
                        
                        <h4>
                            ３）研修期間中のサポート
                        </h4>
                        <p>
                        　上記研修会の間は研修期間に含まれます。使い方のサポート、独自データのkml変換サービスの活用について、随時リモート会議でご相談いただくことが可能です。また、下記に示す可視化に必要な機器等の活用が可能です。</p>
                        <h4>
                            ４）可視化に必要な機器等
                        </h4>
                        <p>
                            ・MacBook Air 13インチ及び付属品または相当品<br/>
                            ・SpaceMouse または相当品<br/>
                            ・Pocket WiFi SoftBank 802ZTまたは相当品（期間中の通信契約含む）
                        </p>
                        <h4>
                            ５）人数及び期間
                        </h4>
                        <p>・ご要望に応じて対応いたします。詳しくは下記参考をご覧ください。</p>

                        <div style={{border:"solid 1px #fff", padding: "15px 5% 5px 5%"}}>


                        <h3>
                            参考：研修の規模等のイメージ例
                        </h3>
                        <p>
                        　１回につき、４〜６人程度を標準としております。（講師一人で対応可能な単位、これより多い場合は講師の増員等により可能な場合もありますので、ご相談ください。）<br/>
                        　ある課の方のみでも、各課１名ずつ等でも構いません。<br/>
                        　研修期間中、可視化の実務での活用が想定される方を人選いただくと無理なく受講いただくことが可能です。<br/><br/>
                        　例えば以下のような事例があります。<br/>
                        　・地元説明会での説明に可視化を活用することを想定して資料作成担当２名、他市町村との勉強会に出席予定の係長と係員２名、まちづくり研修に出席予定の担当者１名の合計６名で受講し活用<br/>
                        　・マスタープラン作成にあたり庁内での議論のために計画担当者６名全員で受講し活用<br/>
                        　・国の計画部署の５名で受講し、他機関協議の効率化のために活用<br/>
                        　・コンサルティングスキルアップのため、コンサルタント会社の若手６名で受講、その後、都市計画ビジュアルコミュニケーター検定を受験。<br/>
                        　・協会会員へのサービスとして３都市において可視化研修を共催、合計４０名に提供<br/>
                        　・大学のゼミ生４名で受講し、地域住民との意見交換やヒアリングの際に活用　　等
                        </p>

                        <p>お申し込みは下記の連絡先よりお願いいたします。<br/>過去の研修動画や研修成果、仕様の事例等のご提供も可能ですので、お気軽にお問い合わせください。<span style={{color:"red"}}></span>
                        </p>
                        </div>


                        <hr/>

                        <h3>
                            よくある質問
                        </h3>

                        <p>
                        Q. なぜこの事業を行っているのですか？<br/>
                        A. 当法人は，まちづくりの主体である行政や民間企業，地域住民等に対して，インターネット及びビジュアライゼーションツールを活用した都市の可視化に関する事業を行い，都市計画やまちづくりの検討に必要な都市構造の把握に寄与することを目的としています。<br/>本事業は普及促進および活用事例の収集ならびに利用者からのフィードバック獲得（サイト改善のアイデア収集等）を目的として行っているものです。
                        </p>

                        <p>
                        Q. 開催場所はどこですか？準備しておくことはありますか？<br/>
                        A. 初回、１日の技術研修についてご説明します。研修講師が、ご指定の場所にお伺いいたします。10人程度が入れる会議スペースの確保をお願いいたします。また、大型ディスプレイまたはプロジェクターをご用意ください。過去の実例（イメージ）は、こちらをご覧ください。
                        <details><summary><span><u>第一回目、一日技術研修の分担イメージ表（クリックで開く）</u></span>
                            </summary>
<br/>
<div style={{width:"80%", marginLeft:"auto", marginRight:"auto"}}>
                            <h3>
                            役割分担及び当日のスケジュール（イメージ：詳細はご相談ください）
                            </h3>

■事前準備
<table>
	<tbody>
		<tr>
			<th style={{width:"70%"}}>内容</th>
			<th style={{textAlign:"center"}}>ご依頼元</th>
			<th style={{textAlign:"center"}}>当機構</th>
		</tr>
		<tr>
			<td>社内広報・募集</td>
			<td style={{textAlign:"center"}}>●</td>
			<td>&nbsp;</td>
		</tr>
		<tr>
			<td>参加者リスト作成</td>
			<td style={{textAlign:"center"}}>●</td>
			<td>&nbsp;</td>
		</tr>
		<tr>
			<td>会場確保（研修会）</td>
			<td style={{textAlign:"center"}}>●</td>
			<td>&nbsp;</td>
		</tr>
		<tr>
			<td>会場確保（懇親会）</td>
			<td style={{textAlign:"center"}}>●</td>
			<td>&nbsp;</td>
		</tr>
		<tr>
			<td>参加者貸与用PC、Wifiルーター、3Dマウスの準備</td>
			<td>&nbsp;</td>
			<td style={{textAlign:"center"}}>●</td>
		</tr>
		<tr>
			<td>参加者配布用資料（プログラム、テキスト等）の準備</td>
			<td>&nbsp;</td>
			<td style={{textAlign:"center"}}>●</td>
		</tr>
	</tbody>
</table>
■当日タイムスケジュール案（１日（午前・午後）の場合）
<table>
	<tbody>
		<tr>
			<th style={{width:"20%"}}>時間</th>
			<th style={{width:"50%"}}>内容</th>
			<th style={{textAlign:"center"}}>ご依頼元</th>
			<th style={{textAlign:"center"}}>当機構</th>
		</tr>
		<tr>
			<td>９：３０</td>
			<td>会場セッティング・各種準備</td>
			<td style={{textAlign:"center"}}>●</td>
			<td style={{textAlign:"center"}}>●</td>
		</tr>
		<tr>
			<td>&nbsp;</td>
			<td>受付</td>
			<td style={{textAlign:"center"}}>●</td>
			<td>&nbsp;</td>
		</tr>
		<tr>
			<td>１０：００</td>
			<td>開会・あいさつ・今日の進め方の説明（10分）</td>
			<td style={{textAlign:"center"}}>●</td>
			<td style={{textAlign:"center"}}>●</td>
		</tr>
		<tr>
			<td>１０：１０</td>
			<td>都市構造可視化の概要説明（３０分）</td>
			<td>&nbsp;</td>
			<td style={{textAlign:"center"}}>●</td>
		</tr>
		<tr>
			<td>１０：４０</td>
			<td>実習１（可視化サイトの使い方①）（５０分）</td>
			<td>&nbsp;</td>
			<td style={{textAlign:"center"}}>●</td>
		</tr>
		<tr>
			<td>１１：３０</td>
			<td>質問など（３０分）</td>
			<td>&nbsp;</td>
			<td style={{textAlign:"center"}}>●</td>
		</tr>
		<tr>
			<td>１２：００</td>
			<td>昼食</td>
			<td style={{textAlign:"center"}}>ー</td>
			<td style={{textAlign:"center"}}>ー</td>
		</tr>
		<tr>
			<td>１４：００</td>
			<td>実習２（可視化サイトの使い方②）（６０分）<br/>
            （kml変換サービス、次回までの進め方、WEB会議の使い方）（３０分）</td>
			<td>&nbsp;</td>
			<td style={{textAlign:"center"}}>●</td>
		</tr>
		<tr>
			<td>１５：３０</td>
			<td>休憩（３０分）</td>
			<td style={{textAlign:"center"}}>ー</td>
			<td style={{textAlign:"center"}}>ー</td>
		</tr>
		<tr>
			<td>１６：００</td>
			<td>ワークショップ（好きな都市を紹介しよう）<br/>
            （準備３０分、発表６０分）</td>
			<td>&nbsp;</td>
			<td style={{textAlign:"center"}}>●</td>
		</tr>
		<tr>
			<td>１７：３０</td>
			<td>事務連絡・閉会（５分）</td>
			<td style={{textAlign:"center"}}>●</td>
			<td style={{textAlign:"center"}}>●</td>
		</tr>
	</tbody>
</table>
</div>
                            </details>
                        </p>

                        <p>
                        Q. 費用はかかりますか？<br/>
                        A. 機材の準備および貸し出し、講師確保、資料の準備等のための実費相当額が必要となりますので、人数および期間に応じてお見積もりいたします。ご予算に合わせた調整（期間の延長や短縮）も可能ですので、お気軽にご相談ください。<br/>　なお、当機構は非営利団体（非営利型一般社団法人）です。
                        </p>

                        <p>
                        Q. 時期はいつでも大丈夫ですか？<br/>
                        A. 限られたプロボノ人員と機材で事業を実施しておりますので、急なご相談には応じられないことがあります。また、時期によってはお引き受けできない場合もございます。大変申し訳ありませんが、予めご了承ください。
                        <span style={{color:"red"}}></span></p>
                        <hr/>


                </div>
            </section>
        </div>

    </Layout>
)

export default Generic